<template>
  <div class="container">
    <van-cell-group inset>
      <van-cell title-class="van-cell-title4" title="预约讯息【快照】" center>
        <template #label>
          <van-row>
            <van-col span="6"><strong>会议室：</strong></van-col>
            <van-col span="18"
              >{{ info.meetingRoom ? info.meetingRoom.brand : "" }}
              {{ info.meetingRoom ? info.meetingRoom.address : "" }}</van-col
            >
          </van-row>
          <van-row>
            <van-col span="6"><strong>预约时间：</strong></van-col>
            <van-col span="18">{{
              dayjs(info.startTime).format("YYMMDD") ==
              dayjs(info.endTime).format("YYMMDD")
                ? dayjs(info.startTime).format("YYYY-MM-DD HH:mm") +
                  " - " +
                  dayjs(info.endTime).format("HH:mm")
                : dayjs(info.startTime).format("YYYY-MM-DD HH:mm") +
                  " - " +
                  dayjs(info.endTime).format("YYYY-MM-DD HH:mm")
            }}</van-col>
          </van-row>
          <van-row>
            <van-col span="6"><strong>预约人：</strong></van-col>
            <van-col span="18"
              >{{ info.hrDepartment }} - {{ info.creator }}
              <a v-if="info.creatorMobile" :href="'tel:' + info.creatorMobile"
                >({{ info.creatorMobile }})</a
              ></van-col
            >
          </van-row>
          <van-row>
            <van-col span="6"><strong>会议主题：</strong></van-col>
            <van-col span="18">{{ info.title }}</van-col>
          </van-row>
          <van-row>
            <van-col span="6"><strong>预约备注：</strong></van-col>
            <van-col span="18">{{ info.creatorMemo }}</van-col>
          </van-row>
          <van-row>
            <van-col span="6"><strong>是否vip：</strong></van-col>
            <van-col span="18">{{ info.isVIP ? "是" : "否" }}</van-col>
          </van-row>
          <van-row>
            <van-col span="6"><strong>场地费：</strong></van-col>
            <van-col span="18">{{ info.venueFee }}</van-col>
          </van-row>
          <van-row>
            <van-col span="6"><strong>套餐费：</strong></van-col>
            <van-col span="18">{{ info.comboFee }}</van-col>
          </van-row>
          <van-row>
            <van-col span="6"><strong>减免费：</strong></van-col>
            <van-col span="18">{{ info.reducedFee }}</van-col>
          </van-row>
          <van-row>
            <van-col span="6"><strong>已选套餐：</strong></van-col>
            <van-col span="18">
              <div style="border-bottom: 1px solid #eee; padding-bottom: 2px;"
                v-for="item in combos.filter(
                  (x) => x.itemType == 0 && x.isSelected == true
                )"
                :key="item.id"
              >
                <h6>
                  {{ item.title }} x {{ item.num
                  }}{{
                    item.chargeType == 0
                      ? "次"
                      : item.chargeType == 1
                      ? "人"
                      : item.chargeType == 2
                      ? "套"
                      : ""
                  }}
                </h6>
                <p>
                  {{ item.description }}
                </p>
              </div>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="6"><strong>已选单项：</strong></van-col>
            <van-col span="18">
              <div style="border-bottom: 1px solid #eee; padding-bottom: 2px;"
                v-for="item in combos.filter(
                  (x) => x.itemType == 1 && x.isSelected == true
                )"
                :key="item.id"
              >
                <h6>
                  {{ item.title }} x {{ item.num
                  }}{{
                    item.chargeType == 0
                      ? "次"
                      : item.chargeType == 1
                      ? "人"
                      : item.chargeType == 2
                      ? "套"
                      : ""
                  }}
                </h6>
              </div>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="6"><strong>提交时间：</strong></van-col>
            <van-col span="18">{{
              dayjs(info.creationTime).format("YYYY-MM-DD HH:mm")
            }}</van-col>
          </van-row>
          <van-row>
            <van-col span="6"><strong>当前状态：</strong></van-col>
            <van-col span="18">{{
              info.status == 0
                ? "已预约"
                : info.status == 1
                ? "已取消"
                : info.status == 2
                ? "审核通过"
                : info.status == 0
                ? "审核不通过"
                : ""
            }}</van-col>
          </van-row>
          <van-row v-if="info.auditMemo">
            <van-col span="6"><strong>审核备注：</strong></van-col>
            <van-col span="18">{{ info.auditMemo }}</van-col>
          </van-row>
        </template>
      </van-cell>
    </van-cell-group>
    <p style="padding:10px;"><a style="float:right;color: gray;" href="http://kmadmin.bjznpz.com/meetingRoom/room"
      >更多信息请登录KM后台查看>></a
    ></p>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  getMeetingRoomReservedInfo,
  getMeetingRoomComboAPI,
} from "../../api/reserveMeeting";
export default {
  data() {
    return {
      info: {},
      combos: [],
    };
  },
  computed: {},
  created() {
    dayjs(new Date());
    this.onload();
  },
  mounted() {},
  watch: {},
  methods: {
    onload() {
      if (!this.$route.query.id) {
        return;
      }
      getMeetingRoomReservedInfo(this.$route.query.id).then((res) => {
        if (res.data.success) {
          this.info = res.data.data;
          if (this.info && this.info.meetingRoomId) {
            getMeetingRoomComboAPI({
              current: 1,
              pageSize: 100,
              filterData: {
                status: 1,
                meetingRoomId: this.info.meetingRoomId,
              },
            }).then((res1) => {
              if (res1.data.success) {
                this.combos = res1.data.data;
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.container {
  height: 100%;
  overflow-y: auto;
  margin-bottom: 100px;
}
.van-cell-title4 {
  -webkit-box-flex: 4;
  flex: 4;
}
</style>
